// Core
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Custom Components
import StyledModal from '../Modal';
import SearchField from '../SearchField';

// Styles
import styles from './style.module.css';

export default function VerticalSelector({
  openModal,
  handleVerticalClick,
  selectVertical,
  verticals,
  selectedVertical,
}) {
  const { t: translate } = useTranslation();
  const [searchTxt, setSearchtxt] = useState('');
  const [verticalsData, setVerticalsData] = useState([]);

  useEffect(() => {
    if (verticals?.indexOf(selectedVertical) > 0) {
      verticals.splice(verticals.indexOf(selectedVertical), 1);
      verticals.unshift(selectedVertical);
    }
    if (!searchTxt?.length) {
      // setDefaultData();
      setVerticalsData(verticals);
    }
    if (searchTxt?.length) {
      // eslint-disable-next-line max-len
      const newData = verticals?.filter((it) => it.verticalName.toLowerCase().includes(searchTxt.toLowerCase()));

      setVerticalsData(newData);
    }
  }, [searchTxt, verticals]);

  return (
    <StyledModal
      open={openModal}
      onClose={handleVerticalClick(false)}
      paperClass={styles.vsiPaper}
    >
      <h3 className={styles.title}> Select a Sector</h3>

      <SearchField
        className={styles.search}
        placeholder={translate('Search Sector')}
        onChange={setSearchtxt}
      />

      {!!verticalsData?.length && (
        <p className={styles.subTitle}> Your Sectors</p>
      )}

      <div className={styles.verticals}>
        {verticalsData?.map((vertical) => (
          <div className={styles.vertical} key={vertical?._id}>
            <div
              className={styles.imageContainer}
              style={{
                backgroundColor: '#F3F3F7',
              }}
            >
              <img
                src={
                  vertical.thumbnailURL
                    ? vertical.thumbnailURL
                    : '/images/groceryBag.png'
                }
                alt={vertical?.verticalName}
              />
              {vertical?.categories?.length === 0 && (
                <span className={styles.comingSoon}>
                  {translate('Coming soon')}
                </span>
              )}
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={selectVertical(vertical)}
              onClick={selectVertical(vertical)}
              className={styles.verticalDetails}
            >
              <div className="row j-space-between w-100">
                {vertical?.verticalName}
                {vertical === selectedVertical ? (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9973 6.61389 17.0482 4.32629 15.3609 2.63905C13.6737 0.951817 11.3861 0.00272979 9 0ZM13.6863 6.85195L8.11755 12.4207C8.034 12.5043 7.93481 12.5706 7.82562 12.6158C7.71644 12.6611 7.59941 12.6844 7.48123 12.6844C7.36304 12.6844 7.24601 12.6611 7.13683 12.6158C7.02765 12.5706 6.92845 12.5043 6.8449 12.4207L4.3137 9.88945C4.14669 9.72033 4.05337 9.492 4.05411 9.25431C4.05485 9.01663 4.1496 8.78889 4.31767 8.62081C4.48573 8.45274 4.71347 8.35798 4.95116 8.35723C5.18884 8.35648 5.41717 8.44979 5.5863 8.6168L7.48123 10.5117L12.4137 5.5793C12.5828 5.41229 12.8112 5.31898 13.0488 5.31973C13.2865 5.32048 13.5143 5.41524 13.6823 5.58332C13.8504 5.75139 13.9452 5.97913 13.9459 6.21682C13.9466 6.4545 13.8533 6.68283 13.6863 6.85195Z"
                      fill="#1A51A3"
                    />
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9" cy="9" r="8.5" stroke="#8991AC" />
                  </svg>
                )}
              </div>
              <span className={styles.description}>
                {vertical.categories.map((c) => c.category_name).join(', ')}
              </span>
            </div>
          </div>
        ))}
      </div>
    </StyledModal>
  );
}
