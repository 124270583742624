// Core Packages
import classnames from 'classnames';

// Material
import Button from '@material-ui/core/Button';

// Styles
import styles from './style.module.css';

export default function StyledLink({
  className,
  children = null,
  link,
}) {
  const buttonClasses = classnames(styles.button, className);

  return (
    <Button
      data-test="component-styled-button"
      className={buttonClasses}
      color="primary"
      href={link}
      target="_blank"
      variant="contained"
    >
      {children}
    </Button>
  );
}
