/* eslint-disable func-names */
import localforage from 'localforage';
import firebase from '../../firebase';

const getStoredToken = async function () {
  return localforage.getItem('fcmToken');
};

const firebaseCloudMessaging = {
  tokenInLocalForage: getStoredToken,
  // initializing firebase app
  async init(callback) {
    try {
      let token;

      const messaging = firebase.messaging();
      const tokenInLocalForage = await getStoredToken();
      // if FCM token is already there just return the token
      if (tokenInLocalForage !== null) {
        token = tokenInLocalForage;
      }

      if ('Notification' in window) {
        // requesting notification permission from browser
        const status = await Notification.requestPermission();

        if (status && status === 'granted') {
          const fcmToken = await messaging.getToken();

          /** When app is active */
          messaging.onMessage(
            (payload) => {
              const notificationTitle = payload.notification.title;
              const notificationOptions = {
                body: payload.notification.body,
                // icon: "/firebase-logo.png",
              };

              // Create notification in the foreground
              // eslint-disable-next-line no-new
              // new Notification(notificationTitle, notificationOptions);

              if (callback) {
                callback(payload);
              }

              navigator.serviceWorker
                .getRegistration('/firebase-cloud-messaging-push-scope')
                .then((registration) => {
                  registration.showNotification(
                    notificationTitle,
                    notificationOptions,
                  );
                });
            },
            (e) => {
              console.log('Error while getting message==>', e);
              if (callback) {
                callback(e);
              }
            },
          );

          if (fcmToken) {
            // setting FCM token in indexed db using localforage
            localforage.setItem('fcmToken', fcmToken);

            token = fcmToken;
          }
        }
      }
      return token;
    } catch (error) {
      console.error('the error is: ', error);
      return null;
    }
  },
};
export default firebaseCloudMessaging;
