import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Link from 'next/link';

import classNames from 'classnames';
import { ChevronRight } from '@/assets/icons/desktop';
import { GET_PRODUCT_FILTERS } from '@/graphql/products';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

// Utilities

const CategoryDropdown = ({
  className,
  category,
  setDropdownContent,
  categoryName,
}) => {
  const { t: translate } = useTranslation();

  const [categoryOpen, setCategoryOpen] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const toggleVisibility = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setCategoryOpen((prevOpen) => !prevOpen);

  // eslint-disable-next-line max-len
  const [getSearchResults, { data: getProductFiltersApp }] = useLazyQuery(GET_PRODUCT_FILTERS);
  const filters = {
    Categories:
      category?.searchTerms?.[0] === 'Dairies & Snacks'
        ? ['Dairies and Snacks', category.categoryName]
        : [category?.searchTerms?.[0], category.categoryName],
    SubCategories: [],
    brands: [],
    discounts: [],
  };

  useEffect(() => {
    const categories = filters.Categories.filter((c) => !!c);
    getSearchResults({
      variables: {
        categories,
        subCategories: filters.SubCategories,
        brands: filters.brands,
      },
    });
  }, [category]);

  useEffect(() => {
    if (!getProductFiltersApp) return;
    const {
      getProductFiltersApp: { brands, subCategories },
    } = getProductFiltersApp;
    setBrandList(brands);
    setSubCatList(subCategories);
  }, [getProductFiltersApp]);

  const itemClasses = classNames(className, styles.categoryListItem);
  const activeItemClasses = classNames(
    className,
    styles.categoryListItem,
    styles.categoryListItemOpen,
  );

  const supplyContent = () => {
    // Handle sending category data
    setDropdownContent(
      <div className={styles.categoryListing}>
        <div className={styles.categoryListingContainer}>
          <h3>SUB CATEGORY</h3>
          <div className={styles.listing}>
            {subCatList.map((subCat) => (
              <Link
                key={category.categoryName}
                href={`/category/${category.categoryName}?cats=${category.categoryName}&sort=newarrival&subCat=${subCat?.key}`}
                // href="/category/[category]?sort=newarrival"
              >
                <p className={styles.listingItem} key={subCat?.key}>
                  {subCat?.key}
                </p>
              </Link>
            ))}
          </div>
        </div>

        <div className={styles.categoryListingContainer}>
          <h3>TOP BRANDS</h3>
          <div className={styles.listing}>
            {brandList.map((brand) => (
              <Link
                key={brand.key}
                href={`/category/${category.categoryName}?cats=${category.categoryName}&sort=newarrival&brand=${brand?.key}`}
                // href="/category/[category]?sort=newarrival"
              >
                <p className={styles.listingItem} key={brand?.key}>
                  {brand?.key}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>,
      category.categoryName,
    );
    toggleVisibility();
  };
  return (
    <div
      className={
        categoryOpen && categoryName === category.categoryName
          ? activeItemClasses
          : itemClasses
      }
      key={category.sequenceNo}
      tabIndex={0}
      onKeyDown={supplyContent}
      role="button"
      onClick={supplyContent}
    >
      <img src={category.icon} alt={translate(category.categoryName)} />
      <p>{translate(`${category.categoryName}`)}</p>
      <ChevronRight className={styles.categoryListItemChevron} />
    </div>
  );
};

export default CategoryDropdown;
