// Core Packages
import { useContext } from 'react';
import classNames from 'classnames';

// Custom Components
import StyledLink from '@/components/Link';

// Utils
import { AppStoreButton, GooglePlayButton } from '@/assets/icons/desktop';

// Contexts
import { GqlContext } from '@/contexts/gql';

// Styles
import styles from './style.module.css';

export default function BottomPromotion() {
  // Contexts
  const { appInfo } = useContext(GqlContext);

  // Styles
  const containerClasses = classNames('col', styles.container);
  const promptContainerClasses = classNames(
    'row a-center j-space-between scroll-x ',
    styles.promptContainer,
  );

  const downloadButtonClasses = classNames(styles.button);

  return (
    <div className={containerClasses}>
      <div className={promptContainerClasses}>
        <p className={styles.title}>
          Stock up your shop
          <br />
          <span>without stress</span>
        </p>
        <img src="/images/iphone.svg" alt="iphone" />
        <div className="col">
          <p className={styles.prompt}>Download the Alerzoshop mobile app</p>
          <div className="row">
            <StyledLink
              link={appInfo?.playstoreUrl}
              className={downloadButtonClasses}
            >
              <GooglePlayButton />
            </StyledLink>

            <StyledLink
              link={appInfo?.appstoreUrl}
              className={downloadButtonClasses}
            >
              <AppStoreButton />
            </StyledLink>
          </div>
        </div>
      </div>
      <p className={styles.about}>
        Alerzo: The One-stop Shopping Destination
        <br />
        <br />
        <span>
          E-commerce is revolutionizing the way we all shop in Nigeria. Why do
          you want to hop from one store to another in search of the latest
          phone when you can find it on the Internet in a single click? Not only
          mobiles. Alerzo houses everything you can possibly imagine, from
          trending electronics like laptops, tablets, smartphones, and mobile
          accessories to in-vogue fashion staples like shoes, clothing and
          lifestyle accessories; from modern furniture like sofa sets, dining
          tables, and wardrobes to appliances that make your life easy like
          washing machines, TVs, ACs, mixer grinder juicers and other
          time-saving kitchen and small appliances; from home furnishings like
          cushion covers, mattresses and bedsheets to toys and musical
          instruments, we got them all covered. You name it, and you can stay
          assured about finding them all here. For those of you with erratic
          working hours, Alerzo is your best bet. Shop in your PJs, at night or
          in the wee hours of the morning. This e-commerce never shuts down.
          <br />
          <br />
          What&apos;s more, with our year-round shopping festivals and events,
          our prices are irresistible. We&apos;re sure you&apos;ll find yourself
          picking up more than what you had in mind. If you are wondering why
          you should shop from Alerzo when there are multiple options available
          to you, well, the below will answer your question.
        </span>
      </p>
    </div>
  );
}
