/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
// Utilities
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import classnames from 'classnames';
// import Categories from '@/containers/Categories';
import StyledCarousel from '@/components/Carousel';
import DesktopDialog from '@/components/DesktopDialog';
import styles from './style.module.css';
import CategoryDropdown from './CategoryDropdown';

export const Header = ({
  heading,
  url = '',
  className,
  onClick,
  lengthCheck = true,
}) => {
  const { t: translate } = useTranslation();

  const containerClasses = classnames(
    'row j-space-between a-center',
    className,
  );

  return (
    <div className={containerClasses}>
      <span className={styles.heading}>{heading}</span>
      {url && !onClick && (
        <Link href={url}>
          <span className={styles.link}>{translate('See all')}</span>
        </Link>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {url && onClick ? (
        lengthCheck ? (
          <span
            className={styles.link}
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
            role="button"
          >
            {translate('See all')}
          </span>
        ) : (
          <span style={{ opacity: 0.6 }} className={styles.link}>
            {translate('See all')}
          </span>
        )
      ) : null}
    </div>
  );
};

const ShopByCategory = ({ categories, bannerImages }) => {
  const anchorRef = useRef(null);
  const { t: translate } = useTranslation();
  const [dropdownContent, setDropdownContent] = useState('');
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [dropdownKey, setDropdownKey] = useState('key');

  const toggleVisibility = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setCategoryOpen((prevOpen) => !prevOpen);

  const largeScreenClasses = classnames(
    styles.outerContainer,
    styles.largeScreenOnly,
  );

  const handleDropdown = (content, catName) => {
    setCategoryName(catName);
    setDropdownContent(content);
    setDropdownKey(`${Math.random()}`);
    if (!categoryOpen) toggleVisibility();
  };
  return categories?.length ? (
    <>
      {/* Small Screens
      <div className={styles.smallScreenOnly}>
        {categories.length > 6 ? (
          <>
            <Header
              className={styles.sectionHeader}
              heading={translate('Shop by Category')}
              url="/category"
            />
            <div className={styles.categories}>
              <Categories
                categories={categories.slice(0, 6)}
                columnsCount={3}
                isHomePage
              />
            </div>
          </>
        ) : (
          <>
            <Header
              className={styles.sectionHeader}
              heading={translate('Shop by Category')}
            />
            <div className={styles.categories}>
              <Categories categories={categories} columnsCount={3} isHomePage />
            </div>
          </>
        )}
      </div> */}

      {/* Large Screens */}
      <div className={largeScreenClasses}>
        <div className={styles.innerContainer}>
          <div className={styles.categoryList}>
            <h2 className={styles.categoryListTitle}>
              {translate('Shop by Category')}
            </h2>
            <div className={styles.scroll} ref={anchorRef}>
              {categories.map((cat, index) => (
                <CategoryDropdown
                  key={index}
                  categoryName={categoryName}
                  category={cat}
                  setDropdownContent={handleDropdown}
                />
              ))}
              <DesktopDialog
                place="right-start"
                open={categoryOpen}
                setOpen={toggleVisibility}
                anchorRef={anchorRef}
                className={styles.dropdownContainer}
                key={dropdownKey}
              >
                {dropdownContent}
              </DesktopDialog>
            </div>
          </div>

          <div className={styles.sliderContainer}>
            <StyledCarousel
              useLargeImage
              className={styles.largeCarousel}
              images={bannerImages}
              src="desktopVersion"
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ShopByCategory;
