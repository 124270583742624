/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
// Core
import { useContext, useState, useEffect } from 'react';
import SEOTags from '@/components/SEO';
import classnames from 'classnames';

// Material
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// Custom Components
import ProductsList from '@/containers/ProductList';
import PageWrapper from '@/components/Layout/PageWrapper';
import StyledCarousel from '@/components/Carousel';
import GridView from '@/components/Grid';
import CitySelector from '@/containers/CitySelector';
import BundlePopup from '@/containers/BundlePopup';
import HorizontalScroll from '@/components/HorizontalScroll';
import CompactProduct from '@/components/CompactProduct';
import DownloadAppPrompt from '@/components/DownloadAppPrompt';
import { FocusArrow } from '@/assets/icons/desktop';

import {
  // NotificationIcon,
  BlueLocationIcon,
  CaretDownIcon,
} from '@/assets/icons/shared';
import ShopByCategory, { Header } from '@/components/ShopByCategory';
import updateFCMToken from '@/hooks/updateFCMToken';

// Utils
import {
  GET_TRENDING_PRODUCTS_BY_CITY,
  GET_BEST_SELLING_PRODUCTS_BY_CITY,
  GET_RECOMMENDED_PRODUCTS_FOR_CUSTOMER,
  GET_BANNERS,
} from '@/graphql/home';
import { GET_PRODUCT_CATEGORIES, GET_VERTICALS } from '@/graphql/products';
import { GET_CUSTOMER_META } from '@/graphql/misc';

// Contexts
import { CartContext } from '@/contexts/cart';
import { AuthUserContext } from '@/contexts/authUser';
import { GqlContext } from '@/contexts/gql';

import {
  getCookie,
  setLocationCookies,
  decodeURIString,
  setCookie,
  removeCookies,
  // getLatLongGooglePlaces,
} from '@/utils/helper';
import { pwaInstalled } from '@/utils/analytics';
// import { AUTH_TOKEN } from '@/utils/oauth';
// import { generateIdToken } from '@/services/auth';
import { warehouseSelector } from '@/utils/warehouseSelector';
import { useRouter } from 'next/router';
import BottomPromotion from '@/components/Layout/BottomPromotion';
import Verticals from '@/containers/Verticals';
import { configureApolloClientSSR, getAuthCookie } from '../../apollo-client';

// Styles
import styles from './style.module.css';

function Home({
  cartItems,
  updateProductQuantity,
  getItemQuantityForBundle,
  recommendedProducts = [],
  stateAndCities = [],
  cityId,
  landmarkId,
  lat,
  long,
  customerId = '',
  cookiesAlreadyExist,
  trendingProducts = [],
  bestSellingProducts = [],
  selectedVertical = '',
  categories,
  verticals = [],
  warehouseForOrder,
  showMiniNotification,
  banners,
  isSmallDevice,
  cityName,
  stateName,
  stateId,
  landmark,
}) {
  updateFCMToken();
  const router = useRouter();
  const [showCitySelector, setShowCitySelector] = useState(false);
  const [userVerticals, setUserVerticals] = useState(verticals);
  const [showBundlePopup, setShowBundlePopup] = useState('');
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(true);
  const [seeAllTrending, setSeeAllTrending] = useState(false);
  const [seeAllBestSelling, setSeeAllBestSelling] = useState(false);
  const [seeAllRecommended, setSeeAllRecommended] = useState(false);
  const [showDesktopBundlePopup, setShowDesktopBundlePopup] = useState(false);
  const [showFocus, setShowFocus] = useState(false);
  const { site } = useContext(GqlContext);

  const { authUser } = useContext(AuthUserContext);
  const { getTotalItemQuantityInCart } = useContext(CartContext);
  const isLoggedIn = !!authUser?.customerId;
  const headerClasses = classnames(
    styles.header,
    styles.focusContainer,
    'row j-space-between a-center',
  );
  const locationClasses = classnames(styles.button, 'row j-start a-center');
  const productGridContainer = classnames(
    styles.hideOnLargeScreens,
    styles.gridSectionHeader,
  );

  const scrollClasses = classnames('maskedOverflowX', styles.list);

  const bannerStyles = classnames(styles.hideOnLargeScreens, styles.banner, [
    !isLoggedIn && styles.endSection,
  ]);
  const handleProductQuantity = (product, quantity) => {
    updateProductQuantity(product, quantity);
  };
  const toggleShowCitySelector = (val) => () => setShowCitySelector(val);

  const handleShowBundle = (bundleReceived) => {
    setShowDesktopBundlePopup(!isSmallDevice);
    setShowBundlePopup(bundleReceived._id);
  };

  useEffect(() => {
    if (!cookiesAlreadyExist) {
      console.log('Setting location cookies');
      setLocationCookies({
        customerId,
        cityId,
        landmarkId,
        warehouse: warehouseForOrder,
        lat,
        long,
        cityName,
        stateName,
        stateId,
        landmark,
      });
    }

    let isInstalled = localStorage.getItem('pwaInstalled') === '1' || false;

    if (
      window.matchMedia('(display-mode: standalone)').matches
      || window.navigator.standalone === true
    ) {
      localStorage.setItem('pwaInstalled', '1');
      isInstalled = true;
    } else {
      window.addEventListener('beforeinstallprompt', () => {
        localStorage.setItem('pwaInstalled', '0');
        isInstalled = false;
      });
      window.addEventListener('onappinstalled', () => {
        localStorage.setItem('pwaInstalled', '1');
        isInstalled = true;
      });
    }
    if (isInstalled && customerId) {
      pwaInstalled(customerId);
    }
  }, []);

  useEffect(() => {
    // Only fire if user is not logged in and only on alerzoshop
    if (authUser?.customerId || site?.isSK) {
      const permittedVerticals = verticals.filter(
        (v) => !!authUser.verticals.find((vert) => v.verticalName === vert),
      );
      setUserVerticals(permittedVerticals);
      if (showFocus) setShowFocus(false);
      return;
    }
    const focusSeen = !!getCookie('guidedFocusMobile');
    if (focusSeen) return;
    setShowFocus(true);
    setCookie('guidedFocusMobile', 'seen');
  }, [authUser]);

  const focusClasses = classnames(styles.focusArrow);

  useEffect(() => {
    const redirectCookie = getCookie('redirect_to_home');

    if (redirectCookie) {
      // Redirect cookie was not deleted
      removeCookies([{ name: 'redirect_to_home', path: '/' }]);
    }
    // const isLocal = process.env.NEXT_PUBLIC_ENV === 'local';
    // if (isLocal) return;
    // generateIdToken();
  }, []);

  useEffect(() => {
    if (!Object.keys(router.query).includes('sort')) {
      setSeeAllBestSelling(false);
      setSeeAllRecommended(false);
      setSeeAllTrending(false);
    }
  }, [router.query]);

  return (
    <>
      <PageWrapper>
        <SEOTags
          title={site?.isSK ? 'SK Express' : 'Alerzoshop'}
          description={site?.isSK ? 'SK Express' : 'Alerzoshop'}
          keywords={site?.isSK ? 'SK Express' : 'Alerzoshop'}
          siteName={site?.isSK ? 'SK Express' : 'Alerzoshop'}
        />

        {seeAllTrending && (
          <ProductsList
            hideDesktopLayout
            query="Trending Products"
            justProducts
            isVeedezHome
            loading={false}
            products={trendingProducts}
            onClose={() => setSeeAllTrending(false)}
            updateProductQuantity={handleProductQuantity}
            cartItems={cartItems}
            getItemQuantityForBundle={getItemQuantityForBundle}
            warehouseForOrder={warehouseForOrder}
            showMiniNotification={showMiniNotification}
            customerId={authUser?.customerId}
          />
        )}
        {seeAllBestSelling && (
          <ProductsList
            hideDesktopLayout
            query="Best Selling"
            justProducts
            loading={false}
            products={bestSellingProducts}
            onClose={() => setSeeAllBestSelling(false)}
            updateProductQuantity={handleProductQuantity}
            cartItems={cartItems}
            getItemQuantityForBundle={getItemQuantityForBundle}
            warehouseForOrder={warehouseForOrder}
            showMiniNotification={showMiniNotification}
            customerId={authUser?.customerId}
          />
        )}

        {seeAllRecommended && (
          <ProductsList
            hideDesktopLayout
            query="Recommended"
            justProducts
            loading={false}
            products={[...recommendedProducts].slice(0, 20)}
            onClose={() => setSeeAllRecommended(false)}
            updateProductQuantity={handleProductQuantity}
            cartItems={cartItems}
            getItemQuantityForBundle={getItemQuantityForBundle}
            warehouseForOrder={warehouseForOrder}
            showMiniNotification={showMiniNotification}
            customerId={authUser?.customerId}
          />
        )}

        {site.isVeedez && (
          <ProductsList
            hideDesktopLayout
            justProducts
            loading={false}
            products={[
              ...recommendedProducts,
              ...bestSellingProducts,
              ...trendingProducts,
            ]}
            onClose={() => setSeeAllRecommended(false)}
            updateProductQuantity={handleProductQuantity}
            cartItems={cartItems}
            getItemQuantityForBundle={getItemQuantityForBundle}
            warehouseForOrder={warehouseForOrder}
            showMiniNotification={showMiniNotification}
            customerId={authUser?.customerId}
          />
        )}

        {!seeAllBestSelling
          && !seeAllTrending
          && !seeAllRecommended
          && !site?.isVeedez ? (
          <>
            <DownloadAppPrompt
              visible={showDownloadPrompt}
              setVisible={setShowDownloadPrompt}
            />
            <div className={headerClasses}>
              {showFocus ? (
                <>
                  <div
                    className={styles.focus}
                    tabIndex={0}
                    onKeyDown={() => setShowFocus(false)}
                    role="button"
                    onClick={() => setShowFocus(false)}
                  />
                  <div
                    className={focusClasses}
                    tabIndex={0}
                    onKeyDown={() => setShowFocus(false)}
                    role="button"
                    onClick={() => setShowFocus(false)}
                  >
                    <FocusArrow />
                    <p className={styles.focusText}>
                      Click here to select your delivery location
                    </p>
                  </div>
                </>
              ) : null}

              <Button
                className={locationClasses}
                onClick={toggleShowCitySelector(true)}
              >
                <div
                  tabIndex={0}
                  onKeyDown={() => setShowFocus(false)}
                  role="button"
                  onClick={() => setShowFocus(false)}
                  className={showFocus ? styles.focusAnchor : 'row a-end'}
                >
                  <BlueLocationIcon />
                  <span className={styles.location}>
                    {`${decodeURIString(stateName)}, ${decodeURIString(
                      cityName,
                    )}`}
                  </span>
                  <CaretDownIcon className={styles.caretDownIcon} />
                </div>
              </Button>
              {/* {!!authUser && (
                <Link href="/notification">
                  <IconButton className={styles.button}>
                    <NotificationIcon />
                  </IconButton>
                </Link>
              )} */}
            </div>
            <StyledCarousel
              className={styles.hideOnLargeScreens}
              src="mobileVersion"
              images={banners?.top?.length ? banners.top : []}
            />

            <div className={styles.bodyContainer}>
              {!!categories.length && (
                <ShopByCategory
                  categories={categories}
                  bannerImages={banners?.top?.length ? banners.top : []}
                />
              )}
              <Verticals
                verticals={userVerticals}
                selectedVertical={selectedVertical}
                showBundle={handleShowBundle}
              />
              {!!trendingProducts.length && (
                <>
                  <Header
                    className={styles.sectionHeader}
                    heading={
                      site?.isSK
                        ? 'Trending on SK Express'
                        : site?.isAlerzo
                          ? 'Trending on Alerzo'
                          : null
                    }
                    onClick={() => setSeeAllTrending(true)}
                    url="/search"
                    lengthCheck={trendingProducts.length > 6}
                  />
                  <HorizontalScroll className={scrollClasses}>
                    {trendingProducts.map((product) => (
                      <CompactProduct
                        key={product._id}
                        className={styles.product}
                        product={product}
                        source="trendingProducts"
                        // loadingType={index < 20 ? 'eager' : 'lazy'}
                        cartQuantity={getTotalItemQuantityInCart(product._id)}
                        onQuantityChange={handleProductQuantity}
                        bundleDetails={[product]}
                        showBundle={handleShowBundle}
                        cartItems={cartItems}
                        getItemQuantityForBundle={getItemQuantityForBundle}
                        showMiniNotification={showMiniNotification}
                      />
                    ))}
                  </HorizontalScroll>
                </>
              )}
              {!!bestSellingProducts.length && (
                <>
                  <Header
                    className={styles.sectionHeader}
                    heading="Best Selling"
                    url="/search"
                    onClick={() => setSeeAllBestSelling(true)}
                    lengthCheck={bestSellingProducts.length > 6}
                  />
                  <HorizontalScroll className={scrollClasses}>
                    {bestSellingProducts.map((product) => (
                      <CompactProduct
                        key={product._id}
                        className={styles.product}
                        product={product}
                        source="bestSelling"
                        cartQuantity={getTotalItemQuantityInCart(product._id)}
                        onQuantityChange={handleProductQuantity}
                        bundleDetails={[product]}
                        showBundle={handleShowBundle}
                        cartItems={cartItems}
                        getItemQuantityForBundle={getItemQuantityForBundle}
                        showMiniNotification={showMiniNotification}
                      />
                    ))}
                  </HorizontalScroll>
                </>
              )}
              <div className={styles.desktopPromotion}>
                <BottomPromotion />
              </div>
              <StyledCarousel
                className={bannerStyles}
                src="mobileVersion"
                images={banners?.bottom?.length ? banners.bottom : []}
              />
              {recommendedProducts?.length ? (
                <div className={productGridContainer}>
                  <Header
                    className={styles.recommendedProductsHeader}
                    heading="Recommended Products"
                    url="/search"
                    onClick={() => setSeeAllRecommended(true)}
                    lengthCheck={recommendedProducts.length > 6}
                  />

                  <GridView gap="1rem">
                    {recommendedProducts.slice(0, 4).map((product) => (
                      <CompactProduct
                        key={product._id}
                        className="card-size"
                        product={product}
                        source="recommendedProducts"
                        cartQuantity={getTotalItemQuantityInCart(product._id)}
                        onQuantityChange={handleProductQuantity}
                        bundleDetails={[product]}
                        showBundle={handleShowBundle}
                        cartItems={cartItems}
                        getItemQuantityForBundle={getItemQuantityForBundle}
                        showMiniNotification={showMiniNotification}
                      />
                    ))}
                  </GridView>
                </div>
              ) : null}
            </div>

            {showCitySelector && (
              <CitySelector
                onClose={toggleShowCitySelector(false)}
                stateAndCities={stateAndCities}
                cityId={cityId}
                stateId={stateId}
                stateName={stateName}
                cityName={cityName}
                landmark={landmark}
                landmarkId={landmarkId}
              />
            )}
            {showBundlePopup && (
              <BundlePopup
                setOpen={setShowBundlePopup}
                updateProductQuantity={updateProductQuantity}
                getItemQuantityForBundle={getItemQuantityForBundle}
                bundleId={showBundlePopup}
                cartItems={cartItems}
                showMiniNotification={showMiniNotification}
                showDesktopPopup={showDesktopBundlePopup}
              />
            )}
          </>
        ) : null}
      </PageWrapper>
    </>
  );
}

export async function getServerSideProps(context) {
  // console.time('timeToLoad');
  const authCookie = getAuthCookie(context);
  const isLoggedIn = !!authCookie;
  const client = configureApolloClientSSR(context);
  const { headers } = context.req;
  const cookies = headers.cookie;
  let cookieVertical = cookies ? getCookie('activeVertical', cookies) : '';

  const allErrors = [];
  const {
    cookiesAlreadyExist,
    customerId,
    cookieCityId,
    cookieLandmarkId,
    warehouseForOrder,
    cookieLat,
    cookieLong,
    stateAndCities,
    cookieCityName,
    cookieStateName,
    cookieStateId,
    cookieLandmark,
  } = await warehouseSelector(cookies, client, allErrors, isLoggedIn);

  let trendingProducts = [];
  let bestSellingProducts = [];
  let categories = [];
  let verticals = [];
  let recommendedProducts = [];

  if (isLoggedIn && customerId) {
    const {
      data: recommendedProductsData,
      error: recommendedProductsDataError,
      errors: recommendedProductsDataErrors,
    } = await client.query({
      query: GET_RECOMMENDED_PRODUCTS_FOR_CUSTOMER,
      variables: {
        customerId,
        warehouseId: warehouseForOrder,
        size: 10,
        verticals: [cookieVertical],
      },
      errorPolicy: 'all',
    });

    recommendedProducts = recommendedProductsData?.getRecommendedProductsForCustomer?.nodes || [];

    if (recommendedProductsDataError || recommendedProductsDataErrors) {
      allErrors.push({
        recommendedProductsDataError,
        recommendedProductsDataErrors,
      });
    }
    if (!cookieVertical) {
      const {
        data: customerMetaData,
        error: customerMetaError,
        errors: customerMetaErrors,
      } = await client.query({
        query: GET_CUSTOMER_META,
        variables: {
          customerId,
        },
        errorPolicy: 'all',
      });
      cookieVertical = customerMetaData?.getCustomerMeta?.vertical || '';
      if (customerMetaError || customerMetaErrors) {
        allErrors.push({
          customerMetaError,
          customerMetaErrors,
        });
      }
    }
  }

  if (cookieCityId && warehouseForOrder) {
    const [
      {
        data: categoriesData,
        error: categoriesDataError,
        errors: categoriesDataErrors,
      },
      {
        data: verticalsData,
        error: verticalsDataError,
        errors: verticalsDataErrors,
      },
      {
        data: trendingProductsData,
        error: trendingProductsDataError,
        errors: trendingProductsDataErrors,
      },
      {
        data: bestSellingProductsData,
        error: bestSellingProductsDataError,
        errors: bestSellingProductsDataErrors,
      },
    ] = await Promise.all([
      client.query({
        query: GET_PRODUCT_CATEGORIES,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: GET_VERTICALS,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { populateAll: true, populateSubcategories: false },
      }),
      client.query({
        query: GET_TRENDING_PRODUCTS_BY_CITY,
        variables: {
          cityId: cookieCityId,
          warehouseId: warehouseForOrder,
          size: 20,
          verticals: [cookieVertical],
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: GET_BEST_SELLING_PRODUCTS_BY_CITY,
        variables: {
          cityId: cookieCityId,
          warehouseId: warehouseForOrder,
          size: 20,
          verticals: [cookieVertical],
        },
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
      }),
    ]);

    if (categoriesDataError || categoriesDataErrors) {
      allErrors.push({ categoriesDataError, categoriesDataErrors });
    }
    if (verticalsDataError || verticalsDataErrors) {
      allErrors.push({ verticalsDataError, verticalsDataErrors });
    }
    if (trendingProductsDataError || trendingProductsDataErrors) {
      allErrors.push({ trendingProductsDataError, trendingProductsDataErrors });
    }

    if (bestSellingProductsDataError || bestSellingProductsDataErrors) {
      allErrors.push({
        bestSellingProductsDataError,
        bestSellingProductsDataErrors,
      });
    }

    trendingProducts = trendingProductsData?.getTrendingProductsByCity?.nodes ?? [];
    bestSellingProducts = bestSellingProductsData?.getBestSellingProductByCity?.nodes ?? [];
    categories = categoriesData?.fetchProductCategory ?? [];
    verticals = [...verticalsData?.fetchVerticals?.nodes]?.sort(
      (a, b) => a.sequenceNo - b.sequenceNo,
    ) ?? [];
  }

  // Banners
  let banners = null;
  const {
    data: bannersData,
    error: bannersDataError,
    errors: bannersDataErrors,
  } = await client.query({
    query: GET_BANNERS,
    errorPolicy: 'all',
    variables: {
      getInActive: false,
      warehouseId: warehouseForOrder,
    },
  });
  if (bannersData?.fetchBanners) {
    banners = bannersData.fetchBanners;
  }

  if (bannersDataError || bannersDataErrors) {
    allErrors.push({ bannersDataError, bannersDataErrors });
  }

  if (allErrors?.length) {
    console.log('Errors from homepage', allErrors);
  }

  // console.timeEnd('timeToLoad');
  return {
    props: {
      trendingProducts,
      bestSellingProducts,
      recommendedProducts,
      stateAndCities,
      cityId: cookieCityId,
      cookiesAlreadyExist,
      categories,
      customerId,
      warehouseForOrder,
      landmarkId: cookieLandmarkId,
      banners,
      lat: cookieLat,
      long: cookieLong,
      cityName: cookieCityName,
      stateName: cookieStateName,
      stateId: cookieStateId,
      landmark: cookieLandmark,
      verticals,
      selectedVertical: cookieVertical,
    },
  };
}

export default Home;
