import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLazyQuery, useMutation } from '@apollo/client';
import classNames from 'classnames';

// Custom Components
import HorizontalScroll from '@/components/HorizontalScroll';
import { Chip } from '@material-ui/core';
import { GET_PRODUCTS_LIST } from '@/graphql/products';
import GridView from '@/components/Grid';
import CompactProduct from '@/components/CompactProduct';
import LoaderDialog from '@/components/LoaderDialog';
import VerticalSelector from '@/components/VerticalSelector';

// Utils
import { getCookie, setCookie } from '@/utils/helper';
import { AuthUserContext } from '@/contexts/authUser';
import { CartContext } from '@/contexts/cart';
import { NotificationContext } from '@/contexts/Notification';
import { UPDATE_CUSTOMER_META } from '@/graphql/misc';
import { CaretRightIcon } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

export default function Verticals({
  verticals = [],
  selectedVertical,
  showBundle,
  className,
}) {
  const router = useRouter();
  const { warehouseId, authUser } = useContext(AuthUserContext);
  const { showMiniNotification } = useContext(NotificationContext);
  const [updateCustomerMeta] = useMutation(UPDATE_CUSTOMER_META);
  // eslint-disable-next-line max-len
  const { getTotalItemQuantityInCart, getCartItems, getItemQuantityForBundle } = useContext(CartContext);

  const [getSearchResults, { data, loading: productsLoading }] = useLazyQuery(
    GET_PRODUCTS_LIST,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [vertical, setVertical] = useState(
    verticals.find((v) => v.verticalName === selectedVertical) || verticals[0],
  );
  const [category, setCategory] = useState(undefined);
  const [products, setProducts] = useState([]);

  const [open, setOpen] = useState(false);
  const handleVerticalClick = (bool) => () => setOpen(bool);
  const selectNewVertical = (vertical) => async () => {
    if (vertical?.categories?.length === 0) return;
    setVertical(vertical);
    setCookie('activeVertical', vertical?.verticalName);
    if (authUser?.customerId) {
      try {
        await updateCustomerMeta({
          variables: {
            customerId: authUser.customerId,
            vertical: vertical?.verticalName,
          },
        });
      } catch (error) {
        console.error('Error while updating customer info', error);
      }
    }
    setOpen(false);
    router.push('/');
  };
  const handleCategoryClick = (category) => () => setCategory(category.category_name);
  const cityId = getCookie('cityId');
  const cartItems = getCartItems();
  useEffect(() => {
    const sortedCateogries = [...vertical?.categories].sort(
      (a, b) => a.sequenceNo - b.sequenceNo,
    );
    const category = sortedCateogries[0]?.category_name || null;
    setCategory(category);
  }, [vertical]);

  useEffect(() => {
    if (!warehouseId) return;
    if (category) {
      const variables = {
        filters: {
          category: [category],
        },
        cityId,
        warehouse: warehouseId,
        size: 4,
      };

      getSearchResults({
        variables: {
          ...variables,
          platform: 'ALERZOSHOP_PWA',
          outOfStock: true,
        },
      });
    } else {
      setProducts([]);
    }
  }, [category, warehouseId]);
  useEffect(() => {
    if (data?.getProducts) {
      setProducts(data?.getProducts?.nodes || []);
    }
  }, [data]);

  const containerClasses = classNames(className, styles.smallScreenOnly);
  const verticalClasses = classNames('row', styles.sectionHeader);
  const scrollClasses = classNames('maskedOverflowX', styles.chipsContainer);
  return (
    <div className={containerClasses}>
      <div className={verticalClasses}>
        <div
          className={styles.vertical}
          role="button"
          tabIndex={0}
          onKeyDown={handleVerticalClick(true)}
          onClick={handleVerticalClick(true)}
        >
          {vertical?.verticalName}
        </div>
        <CaretRightIcon className={styles.caretIcon} />
      </div>
      <VerticalSelector
        openModal={open}
        verticals={verticals}
        handleVerticalClick={handleVerticalClick}
        selectVertical={selectNewVertical}
        selectedVertical={vertical}
      />

      <HorizontalScroll className={scrollClasses}>
        {[...vertical?.categories]
          .sort((a, b) => a.sequenceNo - b.sequenceNo)
          .map((cat) => (
            <Chip
              className={
                cat?.category_name === category
                  ? styles.activeChip
                  : styles.chip
              }
              key={`${cat?.category_name}_${cat?.sequenceNo}`}
              label={cat?.category_name}
              clickable
              onClick={handleCategoryClick(cat)}
            />
          ))}
      </HorizontalScroll>

      {!data && productsLoading && (
        <div className={styles.loadingContainer}>
          <LoaderDialog loading />
        </div>
      )}

      {!!products && (
        <GridView className={styles.sectionHeader} gap="1rem">
          {products.map((product) => (
            <CompactProduct
              key={product._id}
              className="card-size"
              product={product}
              source={category}
              cartQuantity={getTotalItemQuantityInCart(product._id)}
              showBundle={showBundle}
              cartItems={cartItems}
              getItemQuantityForBundle={getItemQuantityForBundle}
              showMiniNotification={showMiniNotification}
            />
          ))}
        </GridView>
      )}
    </div>
  );
}
