/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';

// Custom components
import StyledLink from '@/components/Link';
import { CloseIcon } from '@/assets/icons/home';
import { GqlContext } from '@/contexts/gql';

// Utils
import { isAppleDevice } from '@/utils/helper';

// Styles
import styles from './style.module.css';

const DownloadAppPrompt = ({ visible, setVisible }) => {
  const { site, appInfo } = useContext(GqlContext);

  const linkToStore = site?.isAlerzo
    ? isAppleDevice()
      ? appInfo?.appstoreUrl
      : appInfo?.playstoreUrl
    : 'https://shop.skexpress.ug/';

  const navigateToApp = () => {
    window.open(linkToStore);
  };

  return visible ? (
    <div className={styles.container}>
      <div
        className={styles.closeIcon}
        onClick={() => setVisible(false)}
        onKeyDown={() => setVisible(false)}
        tabIndex={0}
        role="button"
      >
        <CloseIcon />
      </div>
      <div
        className={styles.logoContainer}
        onClick={navigateToApp}
        onKeyDown={navigateToApp}
        role="button"
        tabIndex={0}
      >
        <img
          src={
            site?.isSK
              ? '/images/SKExpressLogo.svg'
              : site?.isAlerzo
                ? '/images/alerzoAppLogo.svg'
                : '/images/alerzoAppLogo.svg'
          }
          alt="Alerzo"
          className={styles.image}
        />
      </div>
      <div
        className={styles.textContainer}
        onClick={navigateToApp}
        onKeyDown={navigateToApp}
        tabIndex={0}
        role="button"
      >
        <h4 className={styles.title}>
          Download the
          {' '}
          {site?.isAlerzo
            ? 'Alerzoshop'
            : site?.isSK
              ? 'SK Express'
              : 'Alerzoshop'}
          {' '}
          App
        </h4>
        <p className={styles.desc}>Get app-exclusive deals</p>
      </div>
      <div
        className={styles.buttonContainer}
        // onClick={navigateToApp}
        // onKeyDown={navigateToApp}
        role="button"
        tabIndex={0}
      >
        <StyledLink className={styles.openAppBtn} link={linkToStore}>
          Open
        </StyledLink>
      </div>
    </div>
  ) : null;
};

export default DownloadAppPrompt;
