/* eslint-disable prefer-destructuring */
import { useEffect, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_FCM_TOKEN } from '@/graphql/auth';
import firebaseCloudMessaging from '@/services/fcm';
import { AuthUserContext } from '@/contexts/authUser';
import { getCookie } from '@/utils/helper';
import { notificationReceived } from '@/utils/analytics';

export default function updateFCMToken() {
  const NEXT_PUBLIC_GRAPHQL = process.env.NEXT_PUBLIC_GRAPHQL;
  const isDev = NEXT_PUBLIC_GRAPHQL.includes('dev');
  const isLocal = process.env.NEXT_PUBLIC_ENV === 'local';
  const shouldKillMessaging = process.env.NEXT_PUBLIC_DISABLE_MESSAGING;

  // Disable messaging on dev-server
  const isLocalHost = typeof window !== 'undefined'
    && window?.location?.href?.includes('localhost');
  const useMessaging = !isLocalHost || !isLocal || !isDev;
  const { init } = firebaseCloudMessaging;
  // const { init, tokenInLocalForage } = firebaseCloudMessaging;
  const [updateToken] = useMutation(UPDATE_FCM_TOKEN);
  const { authUser } = useContext(AuthUserContext);
  const [updated, setUpdated] = useState(false);

  const logger = (val) => {
    notificationReceived(
      val.notification.title,
      val.notification.body,
      authUser?.customerId,
    );
  };
  useEffect(async () => {
    if (shouldKillMessaging || !useMessaging || updated) return;

    if (!authUser) return;

    const oldToken = authUser.fcmToken;

    const newToken = await init(logger);

    const tokenChanged = !(oldToken === newToken);

    const city = getCookie('cityName');
    const cityId = getCookie('cityId');

    if (tokenChanged) {
      updateToken({
        variables: {
          fcmToken: `${newToken}`,
          customerId: authUser.customerId,
          cityId,
          city,
        },
      });
    }
    setUpdated(true);
  }, [authUser]);
}
